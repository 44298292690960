<template>
    <header role="banner">
        <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-dark">
            <a href="/home" class="navbar-brand navbar-brand-logo">
                <img src="//n4cdn.com/logos/Node4LogoFullInverted.svg" height="30" width="auto">
            </a>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                <a id="multi-menu" @click.prevent="toggleMultiMenu" ref="multiMenu">
                    <i class="fa fa-th fa-1x"></i>
                </a>
                <div class="multi-menu-dropdown col-md-3" v-show="showMultiMenu" id="multi-menu-links">
                    <div id="menu-content" class="multi-menu-dropdown-content" v-on:scroll="checkScroll">
                        <div id="menu-links" class="row">
                            <div class="col-md-6" v-for="(link, index) in links" :key="index">
                                <a :href="link.url" target="_blank">{{ link.title }}</a>
                            </div>
                            <div class="col-md-12 text-center link-loader" v-show="loadingLinks && ! links.length > 0">
                                <i class="fa fa-refresh fa-2x fa-spin"></i>
                                <br />
                                <span>Loading...</span>
                            </div>
                        </div>
                    </div>
                    <div class="row full-width-row">
                        <a v-if="menuContainerHeight > 0" id="menu-scroll-btn" :class="{ 'no-scroll': ! scrollable }" @click.prevent="scrollLinks">More</a>
                    </div>
                </div>
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a href="#" class="nav-link ib">{{ user.name }}</a> <span v-if="customerName" class="mr-3">{{ customerName }}</span>
                    </li>
                    <li class="nav-item">
                        <a class="btn btn-danger" :href="logoutUrl">
                            Logout
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <i v-show="layout.closedSidebar" class="fa arrow-right fa-2x open" @click="openSidebar"></i>
    </header>
</template>

<script>
export default {
    name: "LayoutHeader",

    props: {
        clientId: {
            type: String,
            default: null
        },
        config: {
            type: Object,
            default() {
                return {
                    apps: null,
                    links: null
                }
            }
        }
    },

    data() {
        return {
            user: this.$app.user,
            showMultiMenu: false,
            loadingLinks: false,
            links: [],
            menuContainerHeight: 0,
            maxContainerHeight: 245,
            canScroll: true,
            customerName: null
        }
    },

    computed: {
        layout() {
            return {
                closedSidebar: this.$theme.$store.getters['isMainMenuClosed'],
            }
        },

        appMenuClientId() {
            this.$theme.$store.getters['getMenuAppClientId']
        },

        urls() {
            if (this.config !== null) {
                return config
            }

            return this.$theme.$store.getters['getMainMenuUrls']
        },

        logoutUrl() {
            const user = this.user

            return user.logoutUrl()
        },

        scrollable() {
            return this.canScroll
        }
    },

    methods: {
        openSidebar() {
            this.$theme.$store.commit('maximiseMainMenu')
        },

        toggleMultiMenu() {
            this.loadLinks()
            this.showMultiMenu = ! this.showMultiMenu
        },

        loadLinks(app) {
            const clientId = this.clientId
            this.loadingLinks = true

            this.$app.get('logger').info(`Started loading menu links for ${clientId}`)

            this.$app.get('http.request').get(this.config.links, {
                    params: { app_id: clientId }
                })
                .then(resp => {
                    if (resp.status !== 200) {
                        this.$app.get('logger').error('Failed to load app links', resp)
                    } else {
                        this.links = resp.data

                        this.$app.get('logger').debug('App links loaded', this.links)
                    }
                })
                .finally(() => this.loadingLinks = false)
        },

        closeMenu(e) {
            if (!this.$el.contains(e.target)){
                this.showMultiMenu = false
            }
        },

        getScrollContainerElement() {
            return document.getElementById('menu-content')
        },

        getLinkContainerElement() {
            return document.getElementById('menu-links')
        },

        menuHeight() {
            let menuContent = this.getLinkContainerElement()

            if(menuContent.offsetHeight > 0) {
                this.menuContainerHeight = menuContent.offsetHeight
            }
        },

        scrollLinks() {
            let menuContent = this.getScrollContainerElement()

            menuContent.scrollTop += 240;

            this.checkScroll()
        },

        checkScroll() {
            let menuContent = this.getScrollContainerElement()

            if(menuContent.scrollTop + this.maxContainerHeight === this.menuContainerHeight) {
                this.canScroll = false
                return
            }

            this.canScroll = true
        },

    },

    created() {
        if(this.$app.user) {
            const accountId = JSON.parse(atob(this.$app.user.token.split('.')[1])).n4_account_number
            
            this.$app.get('http.request')
                .get(`process.env.API_URL/accountdata/api/SimpleCRMAccounts/GetAccountbyAccountID?accountID=${accountId}`)
                .then((response) => {
                    if(response.data.content != null) {
                        this.customerName = response.data.content.name
                    }
                })
                .catch(error => console.log(error))
        }
    },

    watch: {
        loadingLinks() {
            this.menuHeight()
        },

        showMultiMenu() {
            this.menuHeight()
        }
    }
}
</script>

<style lang="scss">
    @import '../../scss/variables';

    #multi-menu {
        color: rgba(255, 255, 255, 0.5);
    }

    #multi-menu-links {
        background-color: #f5f5f5;
        position: absolute;
        top: 100%;
        z-index: 10;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        padding: 15px 15px 0 15px;
    }

    .multi-menu-dropdown-content {
        max-height: 245px;
        overflow-x: hidden;
        overflow-y: scroll;
        scroll-behavior: smooth;
        margin-bottom: 15px;
    }

    /* Arrow above menu */
    #multi-menu-links::before {
        content: "";
        border-bottom: 10px solid #f5f5f5;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        position: absolute;
        top: -10px;
        right: 190px;
        z-index: 10;
    }

    .navbar {
        border-bottom: 4px solid $orange;
    }

    .link-loader {
        margin-bottom: 15px;
    }

    .no-scroll {
        display: none !important;
    }

    #menu-scroll-btn {
        width: 100%;
        display: block;
        background-color: $orange;
        color: #ffffff;
        text-align: center;
        padding: 10px 0;
    }

    i.open {
        color: #707070;
        position: fixed;
        bottom: 50%;
        left: 2px;
        z-index: 999999;
    }

    a.nav-link.ib {
        display: inline-block;
    }

    span.mr-3 {
        color: $default-orange;
    }
</style>
