<template>
	<div class="auth">
	    <div class="container">
	        <div class="row">
	            <div class="col-sm-12">
	                <div class="logo-container text-center">
	                    <img src="//n4cdn.com/logos/Node4LogoFullInverted.svg" width="375" height="56">
	                </div>
	            </div>
	        </div>
	        <div class="row">
	            <div class="col-sm-6 offset-sm-3 col-sm-offset-3">
                    <div
                        v-if="notification !== null"
                        class="alert"
                        :class="`alert-${notification.type}`">
                            <i class="fa fa-check"></i> {{ notification.message }}
                    </div>
	                <div class="panel card">
	                    <form class="panel-body card-body" action="" method="GET">
	                        <div class="form-group mb-2">
	                            <label for="emailInput">Email address:</label>
	                            <input type="email" v-model="email" id="emailInput" name="email" class="form-control" placeholder="john.doe@myemail.com">
	                        </div>
                            <div class="form-group mb-2">
                                <input type="checkbox" class="mr-1" id="rememberMe" v-model="rememberMe">
                                <label for="rememberMe" class="mb-0"><small>Remember Me</small></label>
                            </div>
	                        <div class="form-actions">
	                            <input type="submit" @click.prevent="login"  value="Continue to login" class="btn btn-block btn-light btn-default">
	                        </div>
	                    </form>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
import cookie from 'cookie'
import moment from 'moment'
import { notifications } from '../../../config'

export default {
	name: 'Login',

	data() {
		return {
			email: null,
			domain: null,
            user: this.$app.user,
            rememberMe: false,
            storedUserEmail: cookie.parse(document.cookie).user_email,
            notification: null
		}
	},

	beforeRouteEnter(to, from, next) {
		next(vm => {
			if (vm.$app.user.isAuthenticated()) {
				next({ name: 'home' })
			} else {
				next()
			}
		})
	},

	methods: {
		login() {
            console.log(this.$app.user)
			const identity = this.$app.get('identity')
			let identityProvider = identity.identify(this.email)

			if (identityProvider === null) {
				identityProvider = identity.getDefaultIdp()
			}

			const loginUrl = this.user.loginUrl({
				loginHint: this.email,
				idpHint: identityProvider.getHint()
            })

            if(this.rememberMe) {
                document.cookie = cookie.serialize('user_email', this.email, {
                    expires: moment().add(10, 'd').toDate()
                })
            }

			this.redirectTo(loginUrl)
		},

		redirectTo(url) {
			window.location.href = url
        }
    },

    watch: {
        rememberMe(newVal, oldVal) {
            if(newVal === false && this.storedUserEmail !== undefined) {
                document.cookie = cookie.serialize('user_email', '', { expires: moment().toDate() })
            }
        }
    },

    mounted() {
        if(this.storedUserEmail !== undefined) {
            this.email = this.storedUserEmail
            this.rememberMe = true
        }
    },

    created() {
        if(Object.keys(this.$route.query).indexOf('pwsuccess') !== -1) {
            this.notification = notifications.success.pwsuccess
        }
    }
}
</script>

<style scoped>
    .logo-container {
        padding: 30px 0;
    }
	.auth {
		height: 100%;
		background-image: url(//n4cdn.com/images/photos/DataCentreRacks.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom center;
	}
</style>
