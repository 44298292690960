<template>
    <div id="sidebar-wrapper">
        <ul class="sidebar-nav">
            <li v-for="(app, index) in apps" v-show="! app.disabled" :key="index">

                <a href="#" v-if="! app.url" @click.prevent="loadLinks(app)" v-html="menuItemText(app.title, app.extra_data.icon)"></a>

                <a :href="app.url" v-else v-html="menuItemText(app.title, app.extra_data.icon)"></a>

                <ul class="sidebar-nav" v-show="isSelectedApp(app)">
                    <li v-for="(link, index) in getAppLinks(app)" v-show="! link.disabled" :key="index">
                        <a :href="link.url" v-html="menuItemText(link.title, null)"></a>
                    </li>
                </ul>
            </li>
        </ul>
        <div class="toggle">
            <i class="fa arrow-left fa-2x" @click="minimiseSidebar"></i>
            <i v-show="minimised" class="fa arrow-right fa-2x" @click="openSidebar"></i>
        </div>
    </div>
</template>

<script>
    import _filter from 'lodash.filter'
    import _assign from 'lodash.assign'
    import _map from 'lodash.map'

    export default {
        name: "LayoutMenu",

        data() {
            return {
                selectedApp: null
            }
        },

        props: {
            config: {
                type: Object,
                default: null
            }
        },

        computed: {
            apps() {
                return this.$theme.$store.getters['getVisibleMenuApps']
            },

            minimised() {
                return this.$theme.$store.getters['isMainMenuMinimised']
            },

            urls() {
                if (this.config !== null) {
                    return this.config
                }

                return this.$theme.$store.getters['getMainMenuUrls']
            },

            loading() {
                return this.$theme.$store.getters['getLoadingResources']
            },

            menuItemText() {
                return (defaultText, icon) => this.minimised ? '<i class="' + icon + ' fa-2x"></i>' : defaultText
            }
        },

        methods: {
            minimiseSidebar() {
                if(this.minimised === true) {
                    return this.closeSidebar()
                }

                this.$theme.$store.commit('minimiseMainMenu')
            },

            closeSidebar() {
                this.$theme.$store.commit('closeMainMenu')
            },

            openSidebar() {
                this.$theme.$store.commit('maximiseMainMenu')
            },

            getAppLinks(app) {
                return this.$theme.$store.getters['getMenuAppLinks'](app)
            },

            loadLinks(app) {
                this.selectedApp = app

                if(this.minimised == true) {
                    this.openSidebar()
                }

                const clientId = app.extra_data.client_id
                app.loadingLinks = true

                this.$app.get('logger').info(`Started loading menu links for ${clientId}`)

                this.$app.get('http.request').get(this.urls.links, {
                    params: { app_id: clientId }
                })
                    .then(resp => {
                    if (resp.status !== 200) {
                        this.$app.get('logger').error('Failed to load app links', resp)
                    } else {
                        this.$theme.$store.commit('setResourceLinks', resp.data)
                    }
                })
                .finally(() => app.loadingLinks = false)
            },

            isSelectedApp(app) {
                return this.selectedApp !== null && this.selectedApp.id === app.id
            }
        },

        created() {
            this.$theme.$store.commit('startLoadingResourceApps')

            this.$app.get('http.request').get(this.urls.apps).then(resp => {
                if (resp.status !== 200) {
                    this.$app.get('logger').error('Failed to load menu apps', resp)
                } else {
                    this.$theme.$store.commit('stopLoadingResourceApps')

                    this.$theme.$store.commit('setResourceApps', resp.data)
                }
            })
        }
    }
</script>

<style lang="scss">
    @import "../../scss/variables";

    #sidebar-wrapper {
        height: 100%;
        background: $gray-800;
        overflow-y: auto;
    }

    .sidebar-nav {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            text-indent: 20px;
            line-height: 40px;
            a,
            i {
                display: block;
                text-decoration: none;
                color: $grey;
                &:hover {
                    text-decoration: none;
                    color: $white;
                    background: rgba(255, 255, 255, 0.2);
                }
                &:active,
                &:focus {
                    text-decoration: none;
                }
            }
            i {
                text-align: center;
                width: 100%;
                text-indent: 0px;
                line-height: 50px;
            }
            .sidebar-nav {
                padding-left: 20px;
            }
        }
    }

    .sidebar-nav > .sidebar-brand {
        height: 65px;
        font-size: 18px;
        line-height: 60px;
        a {
            color: #999999;
            &:hover {
                color: $white;
                background: none;
            }
        }
    }

    .toggle {
        color: rgba(255, 255, 255, 0.3);
        position: absolute;
        bottom: 50%;
        right: 8px;
        .arrow-right {
            margin-left: 2px;
        }
    }
</style>
