<template>
    <div class="row no-gutters h-100">
        <div class="col-12">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: "layout-page-menu-hidden"
    }
</script>

<style scoped>

</style>